<template>
  <div>
    <v-card class="px-4">
      <v-card-title> Modify SMM </v-card-title>
      <v-card-text>
        <!-- <v-form
          v-model="validate"
          @submit.prevent="login"
          ref="form"
          lazy-validation
        > -->
        <v-row>
          <a
            :href="url"
            style="
              align-items: center;
              color: #fff;
              background-color: #4a154b;
              border: 0;
              border-radius: 48px;
              display: inline-flex;
              font-family: Lato, sans-serif;
              font-size: 16px;
              font-weight: 600;
              height: 48px;
              justify-content: center;
              text-decoration: none;
              width: 236px;
            "
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              style="height: 20px; width: 20px; margin-right: 12px"
              viewBox="0 0 122.8 122.8"
            >
              <path
                d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                fill="#e01e5a"
              ></path>
              <path
                d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                fill="#36c5f0"
              ></path>
              <path
                d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                fill="#2eb67d"
              ></path>
              <path
                d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                fill="#ecb22e"
              ></path></svg
            >Grant Slack permissions</a
          >
        </v-row>

        <!-- </v-form> -->
      </v-card-text>
    </v-card>
    <br />
    <v-alert v-if="status == 'ok'" type="success"> Sent succesfully </v-alert>
    <v-alert v-if="status == 'error'" type="error">
      Something is wrong
    </v-alert>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import formValidationMixin from "@/shared/validationRules";

export default {
  data: () => ({
    status: "",
    validate: false,
  }),
  created() {},
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    url() {
      const user_scope =
        "channels:write,groups:write,im:write,mpim:write,users:read,users:write,users.profile:read";
      return `https://slack.com/oauth/v2/authorize?scope=&user_scope=${user_scope}&redirect_uri=${process.env.VUE_APP_BACKEND_URL}/slack/redirect&client_id=${process.env.VUE_APP_SLACK_CLIENT_ID}&state=smmId:${this.getCurrentUser.id}`;
    },
  },
  mixins: [formValidationMixin],
  methods: {
    //   async run() {
    //     if (this.$refs.form.validate()) {
    //       try {
    //         this.status = "ok";
    //       } catch (e) {
    //         this.status = "error";
    //       }
    //     }
    //   },
  },
};
</script>
